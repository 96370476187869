import { gql } from "~/__generated__"
import { AllMembersTable } from "~/admin/users/AllMembersTable"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/shadcn/ui/tabs"
import { ApplicationsTable } from "~/admin/users/ApplicationsTable"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { adminMembersPath } from "~/common/paths"
import { UnfitMembersTable } from "~/admin/users/UnfitMembersTable"
import {
  COMMUNITY_STATISTIC_QUERY_DOCUMENT,
  DataSummaryModules,
} from "~/admin/users/DataSummaryModules"
import { useCommunityFeatures } from "~/community/useCommunity"
import { ActivitiesTable } from "~/admin/users/ActivitiesTable"
import { useQuery } from "@apollo/client"
import { PastMembersTable } from "~/admin/users/PastMembersTable"

export const AdminMembersScreen = () => {
  const { activeTab: activeTabFromParams } = useParams()
  const [activeTab, setActiveTab] = useState(
    activeTabFromParams || "applications"
  )
  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()
  const features = useCommunityFeatures()

  useEffect(() => {
    if (activeTab !== activeTabFromParams) {
      navigate(adminMembersPath({ "activeTab?": activeTab }))
    }
  }, [activeTab, activeTabFromParams, navigate])

  const onTabChange = (tab: string) => {
    setSearchParams({})
    setActiveTab(tab)
  }

  const { data, loading } = useQuery(COMMUNITY_STATISTIC_QUERY_DOCUMENT)
  const communityStatistic = data?.communityStatistic || undefined

  return (
    <div className="container mx-auto px-8">
      <h1 className="text-3xl font-serif text-foreground mt-16 mb-8">
        {communityStatistic?.usersCount} Members
      </h1>
      <DataSummaryModules
        communityStatistic={communityStatistic}
        loading={loading}
      />
      <Tabs value={activeTab} onValueChange={onTabChange}>
        <TabsList className="justify-start mb-4 text-foreground">
          <TabsTrigger value="applications">Applications</TabsTrigger>
          <TabsTrigger value="all">All Members</TabsTrigger>
          <TabsTrigger value="past">Past Members</TabsTrigger>
          <TabsTrigger value="unfit">Unfit Members</TabsTrigger>
          {features.benchmark && (
            <TabsTrigger value="activity">Activity</TabsTrigger>
          )}
        </TabsList>
        <TabsContent value="applications">
          <ApplicationsTable />
        </TabsContent>
        <TabsContent value="all">
          <AllMembersTable />
        </TabsContent>
        <TabsContent value="past">
          <PastMembersTable />
        </TabsContent>
        <TabsContent value="unfit">
          <UnfitMembersTable />
        </TabsContent>
        {features.benchmark && (
          <TabsContent value="activity">
            <ActivitiesTable />
          </TabsContent>
        )}
      </Tabs>
    </div>
  )
}

gql(`
  fragment User_Admin on User {
    ...User_Card
    email
    admin
    activeStripeSubscription {
      id
      status
      plan {
        interval
        intervalCount
      }
    }
    interests {
      name
    }
    expertise {
      name
    }
    place {
      full
    }
    active
    hidden
    onboarded
    createdAt
    fitApplicationSubmittedAt
    fit
    coach

    fitProfile {
      id
      yearsOfExperience
      industry
      linkedinProfileUrl
      companySize
      healthcareVertical
      jobFunction
    }

    tier {
      id
      name
      level
    }
  }
`)

export const USERS_QUERY_DOCUMENT = gql(`
  query AdminUsers(
    $first: Int,
    $after: String,
    $sort: UserSortEnum,
    $searchTerm: String,
    $unreviewedOnly: Boolean,
    $unfitOnly: Boolean,
    $fitOnly: Boolean,
    $placeId: ID,
    $expertiseIds: [ID!],
    $interestIds: [ID!],
    $subscriptionStatus: StripeSubscriptionStatusEnum,
    $onboardedAfter: ISO8601DateTime,
    $tierId: ID,
    $companySize: String,
    $applicationState: String,
    $activeOnly: Boolean,
    $inactiveOnly: Boolean
  ) {
    users(
      first: $first,
      after: $after,
      sort: $sort,
      search: $searchTerm,
      unreviewedOnly: $unreviewedOnly,
      unfitOnly: $unfitOnly,
      fitOnly: $fitOnly,
      placeId: $placeId,
      expertise: $expertiseIds,
      interests: $interestIds,
      subscriptionStatus: $subscriptionStatus,
      onboardedAfter: $onboardedAfter,
      tierId: $tierId,
      companySize: $companySize,
      applicationState: $applicationState,
      activeOnly: $activeOnly,
      inactiveOnly: $inactiveOnly
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...User_Admin
        }
      }
    }
  }
`)

export const USER_ADMIN_UPDATE_MUTATION = gql(`
  mutation UserAdminUpdate($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      user {
        ...User_Admin
      }
    }
  }
`)

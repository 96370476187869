import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { CommunitySlug } from "~/__generated__/graphql"
import { getMetaVar } from "./getMetaVar"
import { merge } from "ts-deepmerge"

const environment = getMetaVar("environment")

type CommunityTranslations = {
  community: {
    creatorFirstName: string
    directoryLabel: string
  }
  signin: {
    signinTitle: string
    signinSubtitle: string
  }
  onboarding: {
    activateScreenTitle: string
    homeBaseLabel: string
    bioScreenTitle: string
    bioFieldLabel: string
    bioFieldPlaceholder: string
    interestsScreenTitle: string
    expertiseScreenTitle: string
    introductionsScreen: {
      title: string
      subtitle: string
      introName: string
      introCompany: string
      introBio: string
      introLocation: string
      introTitle: string
    }
    experienceScreen: {
      companySize?: string
      industry?: string
      linkedinProfileUrl?: string
      oops?: string
      title?: string
      yearsOfExperience?: string
    }
    videoIntro: string
  }
  library: {
    contributeBannerTitle: string
    contributeBannerCopy: string
  }
  unfitScreen: {
    title: string
    body: string
  }
}

const DEFAULT_TRANSLATIONS: CommunityTranslations = {
  community: {
    creatorFirstName: "[CREATOR]",
    directoryLabel: "Directory",
  },
  signin: {
    signinTitle: "Sign in to [COMMUNITY]",
    signinSubtitle: "",
  },
  onboarding: {
    activateScreenTitle: "",
    homeBaseLabel: "Your Location",
    bioScreenTitle: "",
    bioFieldLabel: "",
    bioFieldPlaceholder: "",
    interestsScreenTitle: "What are you most excited to learn about?",
    expertiseScreenTitle: "What do you believe you’re an expert in?",
    introductionsScreen: {
      title: "Wait, one more question!",
      subtitle: "",
      introName: "",
      introCompany: "",
      introBio: "",
      introLocation: "",
      introTitle: "",
    },
    experienceScreen: {
      companySize: "What is your company size?",
      linkedinProfileUrl: "Please drop your Linkedin profile URL",
      oops: "Welp. We missed asking you these questions. Can you help us out and answer these so we can make your membership experience even better?",
      industry: "Which industry do you work in?",
      title: "Title",
      yearsOfExperience: "Years of Experience",
    },
    videoIntro:
      "Before you dive in, here's a short video from {{creatorName}}.<br>We're so glad you're here.",
  },
  library: {
    contributeBannerTitle: "Contribute to the (private) community library!",
    contributeBannerCopy:
      "Share a guide or resource with your peers! And don't worry, what's shared here, stays here.",
  },
  unfitScreen: {
    title: "We're Sorry",
    body: `
      <p>Unfortunately, based on the information you gave us, we won't be able to activate your <CommunityName /> Membership.</p>
      <p>We know this isn't what you want to hear, but rest assured, we are developing future memberships for professionals like you.</p>
      <p>We are excited to connect with you in the future.</p>
      <p>If you have any questions or believe we've made a mistake, please reach out to us at <EmailLink />.</p>
    `,
  },
}

type PartialCommunityTranslations = {
  [K in keyof CommunityTranslations]?: {
    [L in keyof CommunityTranslations[K]]?:
      | string
      | {
          [M in keyof CommunityTranslations[K][L]]?: string
        }
  }
}

const overrideTranslations = (translations: PartialCommunityTranslations) => {
  return merge(DEFAULT_TRANSLATIONS, translations)
}

export const resources: Record<CommunitySlug, any> = {
  [CommunitySlug.Safespace]: overrideTranslations({
    community: {
      creatorFirstName: "Hebba",
    },
    signin: {
      signinTitle: "The HR community that's in your corner",
      signinSubtitle: "For the people prioritizing their people",
    },
    onboarding: {
      activateScreenTitle: "Welcome to your Safe Space",
      bioScreenTitle: "We all have a HR <em>hot</em> take. What's yours?",
      bioFieldLabel:
        "Feel free to also add anything else you'd like displayed on your profile",
      bioFieldPlaceholder:
        "As a new mom, I fight for better parental leave benefits at every organization. I'm also a book worm and love Gone Girl. DM me anytime!",
      introductionsScreen: {
        subtitle:
          "HR is more fun when you can jam with other People Leaders who get it. That’s why our premium memberships includes curated introductions it as a default benefit.",
        introName: "KP Larimore",
        introCompany: "Indiana Packers",
        introBio:
          "Accidentally fell into HR without a formal HR background and education. Strongly feel that experience goes longer than education validation. Always looking to learn different perspectives and ideas.",
        introLocation: "Logansport, IN",
        introTitle: "Sr. HR Generalist & OD Specialist",
      },
      experienceScreen: {
        title: "Your HR experience",
      },
      videoIntro:
        "Hear from Hebba who shares her freshest tips on how to make the most of your membership.",
    },
  }),
  [CommunitySlug.Boardroom]: overrideTranslations({
    community: {
      creatorFirstName: "Blake",
    },
    signin: {
      signinTitle: "Your home base for healthcare strategy",
      signinSubtitle:
        "For the people strengthening the pulse of the healthcare industry",
    },
    onboarding: {
      activateScreenTitle: "Welcome to Hospitalogy!",
      bioScreenTitle:
        "How would you introduce yourself to peer healthcare strategists?",
      bioFieldLabel:
        "This short bio will be used to introduce you to peers bi-weekly!",
      bioFieldPlaceholder:
        "Since starting my career in hospital administration, I've forged hundreds of partnerships for new local clinics to start and scale. I live with my Wife and two children in Nashville, TN! You can likely find me reading Blake's Deep Dives or on the golf course.",
      introductionsScreen: {
        title: "Expand your healthcare network via introductions?",
        subtitle:
          "Get relevant introductions to peers in the community. We curate intros carefully based on your location, interests, and title.  <br><br>Introductions are sent directly via email every other Monday morning! You will be able to set up time to connect with a peer by replying to the email or via Direct Messages in the Hospitalogy web app.",
        introName: "Ann Somers Hogg",
        introBio:
          "Ann Somers is the director of health care research at the Clayton Christensen Institute. Her research focuses on business model innovation and disruption, particularly as they relate to drivers of health and maternal health. Prior to joining the Institute, Ann Somers worked for Atrium Health (now Advocate Health), where she most recently served as the AVP of Strategy and Transformation.",
        introLocation: "Richmond, VA",
        introCompany: "Christensen Institute",
        introTitle: "Director, Health Care Research",
      },
      experienceScreen: {
        title: "Your healthcare experience",
        yearsOfExperience:
          "How many years of healthcare experience do you have?",
      },
    },
    library: {
      contributeBannerTitle:
        "Share resources, research, or notes with the Hospitalogy community",
      contributeBannerCopy:
        "Contribute to the private Hospitalogy library and get listed as an author! Shared knowledge is always better.",
    },
  }),
  [CommunitySlug.Marketingland]: overrideTranslations({
    community: {
      creatorFirstName: "Daniel",
      directoryLabel: "Besties",
    },
    signin: {
      signinTitle: "Where Marketers gather to stay ahead of the curve",
      signinSubtitle:
        "Marketing moves fast.<br>The Marketingland Besties move faster together.",
    },
    onboarding: {
      activateScreenTitle: "Welcome home,<br>Marketing Bestie",
      bioScreenTitle: "How would you introduce yourself to peers in marketing?",
      bioFieldLabel: "Keep it simple like Sabrina Carpenter, short and sweet",
      bioFieldPlaceholder:
        "I’ve been a B2B marketer for 10 years and let me tell you, I love that memes are a part of my vocabulary now. I live in upstate New York with my partner and two dogs. When I’m not churning out content and ideating on new strategies, you can find me in my backyard gardening!",
      interestsScreenTitle:
        "What are you excited to dive into in the next year?",
      expertiseScreenTitle: "What do you believe is your marketing superpower?",
      introductionsScreen: {
        subtitle:
          "Marketing is more fun when you can jam with other top marketers who get it. That’s why our premium memberships include it as a default benefit.",
        introName: "Jay Schwedelson",
        introCompany: "SubjectLine",
        introBio:
          "Jay is the Founder of SubjectLine.com, the leading free subject-line rating tool, ranked in the top 1% of all websites worldwide. He is also the Founder of GURU Media Hub, which hosts the GURU conference, the world’s largest email marketing event, as well as other major marketing events that attract more than 50,000 attendees annually.",
        introLocation: "Boca Raton, FL",
        introTitle: "Marketing Expert",
      },
      experienceScreen: {
        title: "Your marketing experience",
      },
    },
  }),
}

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  resources,
  debug: environment === "development",
  lng: CommunitySlug.Safespace,
  interpolation: {
    escapeValue: false,
  },
  react: {
    transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
    transKeepBasicHtmlNodesFor: ["br", "strong", "em", "i", "p"], // allow <br/> and simple html elements in translations
  },
})

export default i18n
